<script>
    import General from '@/services/General'
    import validationMessages from '@/components/validations'
    import { required, minLength, numeric } from "vuelidate/lib/validators"
    import moment from "moment"
    import flatPickr from "vue-flatpickr-component"
    import vue2Dropzone from "vue2-dropzone"
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

    import "flatpickr/dist/flatpickr.css"
    import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'
    import "vue2-dropzone/dist/vue2Dropzone.min.css"
    import "vue-multiselect/dist/vue-multiselect.min.css"

    export default {
        components:{
            validationMessages,
            flatPickr,
            vueDropzone: vue2Dropzone,
        },
        data() {
            return {
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                date: new Date(),
                popup: {
                    name: '',
                    action: '',
                    action_text: '',
                    type: '' ,
                    date_from: moment().add(1, 'hours').format("YYYY-MM-DD HH:mm"),
                    date_to: moment().add(169, 'hours').format("YYYY-MM-DD HH:mm"),
                    file: null
                },
                date_from_config: {
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: "today",
                    minuteIncrement: 1,
                    defaultDate: new Date(),
                    plugins: [new ConfirmDatePlugin({})]
                },
                date_to_config: {
                    enableTime: true,
                    time_24hr: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: "today",
                    minuteIncrement: 1,
                    defaultDate: new Date(),
                    plugins: [new ConfirmDatePlugin({})]
                },
                dropzoneOptions: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*'
                },
                actionTextOption: [],
                csrf_token: localStorage.getItem('csrf_token')
            };
        },
        validations: {
            popup:{
                name: {
                    required,
                },
            },
        },
        methods: {
            refreshPopupData() { 
                this.$emit('onRefresh') //event from parent
            },
            createHypePopUp(){
                this.tryingToSubmit = true
                this.submitted = true
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToSubmit = false
                    return;
                } 


                let formData = new FormData();
                formData.append('name', this.popup.name)
                formData.append('action', this.popup.action)
                formData.append('action_text', this.popup.action_text)
                formData.append('type', this.popup.type)
                formData.append('date_from', this.popup.date_from)
                formData.append('date_to', this.popup.date_to)
                formData.append('file', this.popup.file)
                formData.append('csrf_token', this.csrf_token)

                General.createHypePopUp(formData)
                    .then((response) => {
                        const res = response.data.data ? response.data.data : false;
                        const error = response.data.error ? response.data.error : '';
                        if(res){
                            this.successmsg('Marketing Popup Created!');
                            this.refreshPopupData();
                            this.closeModal();
                        }else{
                            this.failedmsg('Failed to create new PopUp!')
                        }
                    })
                    .catch(error => {
                        const resData = error.response.data.error ? error.response.data.error : 'Failed';
                        const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                        this.failedmsg(errorMsg)
                    })
                    .finally(() => {
                        this.tryingToSubmit = false
                    })
                
            },
            closeModal() {
                this.showModal = false
            },
            removeImage(){
                this.$refs['file-input'].reset()
            },

            resetProps(){
                this.submitted = false
                this.popup.name = ''
                this.popup.action = ''
                this.popup.type = ''
                this.popup.action_text = ''
                this.popup.date_from = moment().add(1, 'hours').format("YYYY-MM-DD HH:mm")
                this.popup.date_to = moment().add(169, 'hours').format("YYYY-MM-DD HH:mm")
                this.popup.file = null
            },
            selectFile(files){
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone.getQueuedFiles();
                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one image')
                        files.forEach((value) => {
                            this.$refs.myVueDropzone.removeFile(value)
                        });
                    }
                    this.popup.file = queuedFiles[0];
                }, 250);
            },
            getactionTextOptions(){
                General.getactionTextOptions()
                    .then((response) => {
                        this.actionTextOption = response.data.data ? response.data.data : []
                        if(this.actionTextOption){
                            this.popup.action_text = this.actionTextOption[0]
                        }
                    })
                    .catch(error => {
                        this.actionTextOption = []
                    })
            }
        }
    };
</script>

<template>
    <b-modal @shown="getactionTextOptions()" id="add_new_hype_popup" size="md" v-model="showModal" @hidden="resetProps" title="Add New Marketing Popup" title-class="font-18">
    <form @submit.prevent="createHypePopUp">
        <div class="row">
          <div class="col-12">

                <div class="mb-3">
                    <label class="control-label form-label">Image</label>
                    <vue-dropzone
                            id="dropzone"
                            ref="myVueDropzone"
                            :use-custom-slot="true"
                            :options="dropzoneOptions"
                            @vdropzone-files-added="selectFile"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </div>
                

                <b-form-group label="Name" label-for="popup.name" class="mb-3">
                    <b-form-input
                            v-model.trim="popup.name"
                            type="text"
                            id="popup.name"
                            :class="{
                                'is-invalid': submitted && $v.popup.name.$error,
                                }"
                    >
                    </b-form-input>
                    <validationMessages v-if="submitted" :fieldName="'Name'" :validationName="$v.popup.name"></validationMessages>
                </b-form-group>

                <div class="mb-3">
                    <label class="control-label form-label">Action (Url)</label>
                    <b-form-input v-model="popup.action"></b-form-input>
                </div>

                <div class="mb-3">
                    <label class="control-label form-label">Action Text</label>
                    <select v-model="popup.action_text" class="form-control form-select" name="option" type="select">
                        <option v-for="option in actionTextOption" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
              <div class="mb-3">
                  <label class="control-label form-label">Type</label>
                  <select v-model="popup.type" class="form-control form-select" name="type" type="select">
                      <option value="url">url</option>
                      <option value="deeplink">deeplink</option>
                      <option value="webview">webview</option>
                  </select>
              </div>

                <div class="mb-3">
                    <label class="form-label">Date From</label>
                    <flat-pickr
                            v-model="popup.date_from"
                            :config="date_from_config"
                            class="form-control"
                    />
                </div>
                <div class="mb-3">
                    <label class="form-label">Date To</label>
                    <flat-pickr
                            v-model="popup.date_to"
                            :config="date_to_config"
                            class="form-control"
                    />
                </div>

          </div>
        </div>

        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="createHypePopUp" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Submit
            </b-button>
        </template>

    </b-modal>
 
</template>